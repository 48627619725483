// extracted by mini-css-extract-plugin
export var barHeading = "UserProjectProposals__barHeading__CBG10";
export var column = "UserProjectProposals__column__t1zAQ";
export var container = "UserProjectProposals__container__g9GS2";
export var contentWrapper = "UserProjectProposals__contentWrapper__DXp8r";
export var dropdownBody = "UserProjectProposals__dropdownBody__hhsQm";
export var dropdownBodyHeading = "UserProjectProposals__dropdownBodyHeading__ycPzm";
export var dropdownBodyRow = "UserProjectProposals__dropdownBodyRow__Hf8ht";
export var dropdownTrigger = "UserProjectProposals__dropdownTrigger__qDJdU";
export var editProject = "UserProjectProposals__editProject__tCHHr";
export var emptyProposals = "UserProjectProposals__emptyProposals__pmIFU";
export var filters = "UserProjectProposals__filters__XZ7QF";
export var headerOptions = "UserProjectProposals__headerOptions__FMHKZ";
export var label = "UserProjectProposals__label__vSKrO";
export var linkContainer = "UserProjectProposals__linkContainer__LKppb";
export var linkContainerList = "UserProjectProposals__linkContainerList__uVpS2";
export var linkCounter = "UserProjectProposals__linkCounter__vJFHn";
export var listing = "UserProjectProposals__listing__HFZkp";
export var menuHeightNormalizer = "UserProjectProposals__menuHeightNormalizer__gsOAj";
export var paginationBar = "UserProjectProposals__paginationBar__i2Ms8";
export var paginationInfo = "UserProjectProposals__paginationInfo__p74yF";
export var popoverBody = "UserProjectProposals__popoverBody__zYz0m";
export var popoverIconActions = "UserProjectProposals__popoverIconActions__CB3Kc";
export var popoverRow = "UserProjectProposals__popoverRow__JjsyK";
export var popoverTrigger = "UserProjectProposals__popoverTrigger__pdpSf";
export var projectStatus = "UserProjectProposals__projectStatus__LmPlM";
export var projectStatusC = "UserProjectProposals__projectStatusC__mngJK";
export var proposalsCount = "UserProjectProposals__proposalsCount__XPMin";
export var proposalsCountSortPair = "UserProjectProposals__proposalsCountSortPair__UlIhL";
export var proposalsList = "UserProjectProposals__proposalsList__h9Z0q";
export var row = "UserProjectProposals__row__RJA0P";
export var searchFieldInput = "UserProjectProposals__searchFieldInput__SMLiO";
export var searchIcon = "UserProjectProposals__searchIcon__bkWAV";
export var sortSelection = "UserProjectProposals__sortSelection__UyVmN";
export var title = "UserProjectProposals__title__MwpJx";
export var titleMain = "UserProjectProposals__titleMain__GmxhB";
export var titleOptionsPair = "UserProjectProposals__titleOptionsPair__syWv8";
export var underlinedLink = "UserProjectProposals__underlinedLink__SUXvL";